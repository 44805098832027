import React, { Component } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/shared/seo";

import Header from "../components/features/Header";
import Slices from "../components/shared/Slices";

class FeaturePage extends Component {
  // componentDidMount() {
  // const { data } = this.props;
  // const pageTitle = data.prismicFeaturesIndividualPage.data.hero_title;
  // window.analytics.page(pageTitle);
  // }

  render() {
    const {
      location,
      pageContext: { locale },
      data,
    } = this.props;
    const pageData = data.prismicFeaturesIndividualPage.data;

    return (
      <Layout location={location} locale={locale} pageData={pageData}>
        <Seo
          title={`${pageData.meta_title} | Workmate`}
          description={pageData.meta_description}
          image={pageData.meta_image.url}
        />
        <Header data={pageData} />
        <Slices slices={pageData.body} />
      </Layout>
    );
  }
}

export default FeaturePage;

export const query = graphql`
  query FeaturePageQuery($localeKey: String, $slug: String) {
    prismicFeaturesIndividualPage(
      lang: { eq: $localeKey }
      uid: { eq: $slug }
    ) {
      data {
        feature_name
        navigation_cta_button_text
        navigation_cta_button_url
        meta_title
        meta_description
        meta_image {
          url
        }
        hero_title
        hero_description
        hero_image {
          alt
          gatsbyImageData
        }
        desktop_primary_button_text
        desktop_secondary_button_text
        mobile_primary_button_text
        button_url
        hubspot_form_id
        body {
          ... on PrismicFeaturesIndividualPageDataBodyLogos {
            id
            slice_type
            primary {
              title
              description
            }
            items {
              logo {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicFeaturesIndividualPageDataBodyFeatures {
            id
            slice_type
            primary {
              title
            }
            items {
              feature_name
              feature_title
              feature_description
              link_url
              link_text
              icon {
                url
                alt
              }
              image {
                alt
                url
              }
            }
          }
          ... on PrismicFeaturesIndividualPageDataBodyFeaturesInGrid {
            id
            slice_type
            primary {
              title
              sub_title
            }
            items {
              icon {
                url
                alt
              }
              feature_title
              feature_description
              link_text
              link_url
            }
          }
          ... on PrismicFeaturesIndividualPageDataBodyCtaBanner {
            id
            slice_type
            primary {
              title
              description
              cta_button_url
              cta_desktop_primary_button_text
              cta_desktop_secondary_button_text
              cta_mobile_primary_button_text
              cta_hubspot_form_id
            }
          }
        }
      }
    }
  }
`;
