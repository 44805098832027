import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { fontFG18l, fontFG24l, fontFG52m } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import Grid from "../../styles/Grid";
import Button from "../shared/CTAButton";
import HubspotFormModal from "../shared/HubspotFormModal";
import { withContext } from "../shared/withContext";
import { appendUtmContentToUrl } from "../utilities/utmUtils";

const SubTitle = styled.div`
  ${fontFG18l};
  letter-spacing: 1px;
  text-transform: uppercase;
`;
const Title = styled.h1`
  ${fontFG52m};
`;
const Description = styled.h5`
  ${fontFG24l};
`;
const ButtonRow = styled.div``;
const DesktopButton = styled(Button)`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    margin: 0 8px;
    display: inline-block;
  }
`;
const MobileButton = styled(Button)`
  width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;
const Image = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;

const Container = styled(Grid)`
  padding-top: 68px;
  text-align: center;

  ${SubTitle} {
    margin-bottom: 16px;
  }
  ${Title} {
    grid-column: 2 / span 4;
    margin-bottom: 16px;
  }
  ${Description} {
    grid-column: 2 / span 4;
    margin-bottom: 48px;
  }
  ${ButtonRow} {
    margin-bottom: 48px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 80px;

    ${Title} {
      grid-column: 4 / span 8;
    }
    ${Description} {
      grid-column: 4 / span 8;
    }
    ${Image} {
      grid-column: 3 / span 10;
    }
  }
`;

class Header extends React.Component {
  state = {
    bookDemoModalOpen: false,
    featureName: undefined,
  };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ featureName: data.feature_name });
  }

  openBookDemoModal = () => {
    this.setState({ bookDemoModalOpen: true });
  };

  onCancelModal = () => {
    this.setState({ bookDemoModalOpen: false });
  };

  // onButtonClick = (buttonText) => {
  // window.analytics.track(`Clicked ${buttonText}`, {
  //   label: `${this.state.featureName || "Features"} Hero`,
  // });
  // };

  render() {
    const {
      data,
      context: { utmContent },
    } = this.props;
    const { bookDemoModalOpen } = this.state;

    let url = data.button_url;
    if (url && utmContent) {
      url = appendUtmContentToUrl(url, utmContent);
    }
    const heroImageData = getImage(data.hero_image);

    return (
      <Container>
        {data.feature_name && <SubTitle>{data.feature_name}</SubTitle>}
        <Title>{data.hero_title}</Title>
        <Description>{data.hero_description}</Description>
        <ButtonRow>
          {data.desktop_primary_button_text && (
            <DesktopButton
              target="_blank"
              href={url || null}
              onClick={() => {
                // this.onButtonClick(data.desktop_primary_button_text);
                !url && this.openBookDemoModal();
              }}
            >
              {data.desktop_primary_button_text}
            </DesktopButton>
          )}
          {data.desktop_secondary_button_text && (
            <DesktopButton type="outline" onClick={this.openBookDemoModal}>
              {data.desktop_secondary_button_text}
            </DesktopButton>
          )}
          {data.mobile_primary_button_text && (
            <MobileButton
              target="_blank"
              href={url || null}
              onClick={() => {
                // this.onButtonClick(data.mobile_primary_button_text);
                !url && this.openBookDemoModal();
              }}
            >
              {data.mobile_primary_button_text}
            </MobileButton>
          )}
        </ButtonRow>
        {heroImageData && (
          <Image>
            <GatsbyImage
              image={heroImageData}
              alt={data.hero_image.alt || ""}
            />
          </Image>
        )}
        <HubspotFormModal
          targetId="features_page_hubspot_form"
          formId={data.hubspot_form_id}
          visible={bookDemoModalOpen}
          onCancel={this.onCancelModal}
        />
      </Container>
    );
  }
}

export default withContext(Header);
